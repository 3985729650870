import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
import styled from "@emotion/styled"
import { theme, mediaQueries } from "./theme"
import { Container } from "./Container"
import { css } from "@emotion/core"

const AllPostsContainer = styled(Container)`
  padding-top: 30px;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 40px;
  background-color: ${theme.colors.white};
  ${mediaQueries.small(
    css`
      padding-top: 60px;
      padding-bottom: 120px;
      padding-left: 0;
      padding-right: 0;
    `
  )};
`

const Row = styled("div")`
  ${mediaQueries.large(
  css`
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
    `
  )};
`

const ColPostHeading = styled("div")`
  padding: 0 40px;
  ${mediaQueries.medium(
    css`
      padding: 0px 60px;
    `
  )};
  ${mediaQueries.large(
    css`
      padding: 0 76px;
      max-width: 489px;
      flex: 0 0 489px;
    `
  )};
`

const PostsHeading = styled("h2")`
  margin-bottom: .5em;
  font-size: ${theme.headingsMobile.h1};
  color: ${theme.colors.blue};
  line-height: 1;
  font-weight: 700;
  ${mediaQueries.medium(
    css`
      font-size: ${theme.headings.h1};
    `
  )};
`

const ColPosts = styled("div")`
  padding: 0 40px;
  ${mediaQueries.medium(
    css`
      padding-left: 60px;
      padding-right: 60px;
    `
  )};
  ${mediaQueries.large(
    css`
      flex: 1;
    `
  )};
`

const AllPostsList = styled("ul")`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-left: -30px;
  margin-right: -30px;
`;

const AllPostsItem = styled("li")`
  margin-bottom: 40px;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 700;
  width: 401px;
  a {
    text-decoration: none;
    color: ${theme.colors.blue};
    &:hover {
      text-decoration: none;
    }
  }

  h2 {
    font-weight: 700;
    font-size: ${theme.headings.h2};
  }
`;

const PostDate = styled("p")`
  margin-bottom: 20px;
`

const PostExcerpt = styled("p")`
  color: ${theme.colors.gray};
  margin-bottom: 20px;
`

const PostLink = styled("p")`
  font-weight: 700;
  &:hover {
    text-decoration: underline;
  }
`

class AllPosts extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    return (
      <AllPostsContainer line id="zajima-nas">
        <Row>
          <ColPostHeading>
            <PostsHeading>Zajímá nás</PostsHeading>
          </ColPostHeading>
          <ColPosts>
            <AllPostsList>
              {posts &&
                posts.map(({ node: post }) => (
                  <AllPostsItem key={post.id}>
                    <Link to={post.fields.slug}>
                      <h2>{post.frontmatter.title}</h2>
                      <PostDate>{post.frontmatter.date}</PostDate>
                      <PostExcerpt>{post.frontmatter.description}</PostExcerpt>
                      <PostLink>Přečíst článek</PostLink>
                    </Link>
                  </AllPostsItem>
                ))}
            </AllPostsList>
          </ColPosts>
        </Row>
      </AllPostsContainer>
    );
  }
}

AllPosts.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  })
};

export default () => (
  <StaticQuery
    query={graphql`
      query AllPostsQuery {
        allMarkdownRemark(
          limit: 1000
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "post" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                description
                date(formatString: "D. M. YYYY")
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <AllPosts data={data} count={count} />}
  />
);
