import * as React from "react"
import ReactModal from "react-modal"
import styled from "@emotion/styled"
import { css, ClassNames } from "@emotion/core"
import { theme, mediaQueries } from "./theme"

ReactModal.setAppElement(`#___gatsby`)

interface Props {
  children: any
}

interface State {
  isOpen: boolean
}

const ModalOpenButton = styled("button")`
  display: block;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  appearance: none;
  padding: 0;
  outline: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
`

export class Modal extends React.PureComponent<Props, State> {
  constructor(props: any) {
    super(props)
    this.state = { isOpen: false }
  }

  handleOpen = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    e.preventDefault()
    this.setState({ isOpen: true })
  }

  handleClose = () => {
    this.setState({ isOpen: false })
  }

  render() {
    const styles = css`
      .ReactModal__Overlay {
        z-index: 9999;
        background-color: rgba(0, 0, 0, 0.95) !important;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        padding: 50px;
      }

      .ReactModal__Content {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        border: 0;
        background-color: transparent;
        pointer-events: none;
        &:focus {
          outline: 0;
        }
        & > div {
          max-width: 1000px;
          margin-left: auto;
          margin-right: auto;
          & > div {
            pointer-events: all;
          }
          &:nth-of-type(2) {
            margin-top: 50px;
          }
          &:last-of-type {
            margin-bottom: 50px;
          }
        }
      }

      .ReactModal__Close {
        z-index: 1;
        position: fixed;
        right: 10px;
        top: 10px;
        width: 35px;
        height: 35px;
        pointer-events: all;
        ${mediaQueries.extraLarge(
          css`
            right: 40px;
            top: 40px;
          `
        )};
        &:hover {
          cursor: pointer;
        }

        &::before,
        &::after {
          content: "";
          position: absolute;
          height: 2px;
          width: 100%;
          top: 50%;
          left: 0;
          background: ${theme.colors.white};
          margin-top: -2px;
        }

        &::before {
          transform: rotate(45deg);
        }
        &::after {
          transform: rotate(-45deg);
        }
      }
    `
    return (
      <>
        <ModalOpenButton onClick={this.handleOpen} aria-label="Open gallery" />
        <ClassNames>
          {({ css }) => (
            <ReactModal
              isOpen={this.state.isOpen}
              shouldCloseOnOverlayClick={true}
              onRequestClose={this.handleClose}
              portalClassName={css(styles)}
              className="ReactModal"
            >
              <div
                className="ReactModal__Close"
                onClick={this.handleClose}
              />
              {this.props.children}
            </ReactModal>
          )}
        </ClassNames>
      </>
    )
  }
}
