import * as React from "react"
import { theme } from "./theme"
import styled from "@emotion/styled"

interface ButtonProps {
  children: React.ReactNode
  to: string
  className?: string
  onClick?: any
}

const ButtonStyled = styled("a")`
  display: inline-block;
  min-width: 195px;
  padding: 8px 27px;
  text-decoration: none;
  text-align: center;
  color: ${theme.colors.blue};
  background-color: ${theme.colors.white};
  font-weight: 700;
  border: 1px solid ${theme.colors.blue};
  border-radius: 21px;
  transition: all .2s ease-in-out;

  &:hover {
    background-color: ${theme.colors.blueDarken};
    color: ${theme.colors.white};
    border-color: ${theme.colors.blueDarken};
  }
`

class Button extends React.Component<ButtonProps> {
  render() {
    const { children, className, onClick, to } = this.props

    return (
      <ButtonStyled href={to} onClick={onClick} className={className}>
        {children}
      </ButtonStyled>
    )
  }
}

export default Button;
