import React from "react"
import styled from "@emotion/styled"
import { theme, mediaQueries } from "./theme"
import { css } from "@emotion/core"
import { useStaticQuery, graphql } from "gatsby"

const ColEventHeadingWrapper = styled("div")`
  padding-top: 60px;
  ${mediaQueries.large(
    css`
      padding-top: 90px;
    `
  )};
`

const ColEventHeading = styled("div")`
  padding: 70px 40px;
  background-color: ${theme.colors.blue};
  ${mediaQueries.medium(
    css`
      padding: 70px 60px;
    `
  )};
  ${mediaQueries.large(
    css`
      padding: 99px 76px;
      max-width: 489px;
      flex: 0 0 489px;
    `
  )};
`

const EventsHeading = styled("h2")`
  margin-bottom: 35px;
  font-size: ${theme.headingsMobile.h1};
  color: ${theme.colors.white};
  line-height: 1;
  font-weight: 700;
  ${mediaQueries.medium(
    css`
      font-size: ${theme.headings.h1};
    `
  )};
`

const EventsParagraph = styled("p")`
  color: ${theme.colors.white};
`

export default () => {
  const data = useStaticQuery(graphql`
    query EventsHeadingQuery {
      markdownRemark(frontmatter: { templateKey: { eq: "settings" } }) {
        frontmatter {
          eventHeading
          eventClaim
        }
      }
    }
  `)

  return (
    <>
      <ColEventHeadingWrapper>
        <ColEventHeading>
          <EventsHeading>
            {data.markdownRemark.frontmatter.eventHeading}
          </EventsHeading>
          {data.markdownRemark.frontmatter.eventClaim &&
            data.markdownRemark.frontmatter.eventClaim ? (
            <EventsParagraph>
              {data.markdownRemark.frontmatter.eventClaim}
            </EventsParagraph>
          ) : null }
        </ColEventHeading>
      </ColEventHeadingWrapper>
    </>
  )
}
