import React from "react"
import Layout from "../components/Layout"
import Jumbotron from "../components/Jumbotron"
import CTAEvent from "../components/CTAEvent"
import Events from "../components/Events"
import PastEvents from "../components/PastEvents"
import CTAContact from "../components/CTAContact"
import AllPosts from "../components/AllPosts"

if (typeof window !== "undefined") {
  /* tslint:disable */
  require("smooth-scroll")('a[href*="#"]', {
    speed: "500",
    easing: "easeInOutQuad",
    updateURL: false
  });
  /* tslint:enable */
}

export default function Home() {
  return (
    <Layout
      title="Educon Prague"
      description="Chceme měnit současnost a ovlivňovat budoucnost českého vzdělávání. Otevíráme diskuze na aktuální témata, přinášíme příklady dobré praxe a inspirace."
    >
      <Jumbotron />
      <CTAEvent />
      <Events />
      <AllPosts />
      <PastEvents />
      <CTAContact />
    </Layout>
  )
}
