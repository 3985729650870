import React, { Component } from "react";
import { Link, graphql, StaticQuery } from "gatsby"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { theme, mediaQueries } from "./theme"
import { Container } from "./Container"
import Button from "./Button"
import { css } from "@emotion/core"
import PDFIcon from "./icon_pdf.svg"
import Slider from "react-slick";
import Img from "gatsby-image"
import CameraIcon from "./icon_camera.svg"
import { Modal } from "./Modal"

const EventContainer = styled(Container)`
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 80px;
  background-color: ${theme.colors.grayLight};
  ${mediaQueries.small(
    css`
      padding-bottom: 110px;
      padding-left: 0;
      padding-right: 0;
    `
  )};
`

const Row = styled("div")`
  ${mediaQueries.large(
    css`
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
    `
  )};
`

const ColEventHeadingWrapper = styled("div")`
  padding-top: 60px;
  ${mediaQueries.large(
    css`
      padding-top: 150px;
    `
  )};
`

const ColEventHeading = styled("div")`
  padding: 0 40px;
  ${mediaQueries.medium(
    css`
      padding: 0px 60px;
    `
  )};
  ${mediaQueries.large(
    css`
      padding: 0 76px;
      max-width: 489px;
      flex: 0 0 489px;
    `
  )};
`

const EventsHeading = styled("h2")`
  margin-bottom: 35px;
  font-size: ${theme.headingsMobile.h1};
  color: ${theme.colors.blue};
  line-height: 1;
  font-weight: 700;
  ${mediaQueries.medium(
    css`
      font-size: ${theme.headings.h1};
    `
  )};
`

const EventYear = styled("div")`
  margin-bottom: 35px;
  font-size: 30px;
  font-weight: 700;
  color: ${theme.colors.blue};
`

const ColEvents = styled("div")`
  padding-top: 20px;
  overflow: hidden;
  ${mediaQueries.large(
    css`
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: calc(100% - 489px);
      padding-top: 50px;
    `
  )};

  .slick-slider {
    padding-right: 60px;
    user-select: text;
    ${mediaQueries.small(
      css`
        padding-right: 100px;
      `
    )};
    ${mediaQueries.medium(
      css`
        padding-right: 150px;
      `
    )};
    &.draggable {
      user-select: text;
    }
  }

  .slick-list {
    overflow: visible;
  }

  .slick-track {
    display: flex;
    margin-left: 0;
    margin-right: 0;
  }

  .slick-slide {
    display: flex;
    height: auto;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    &:not(.slick-active) {
      opacity: 0.3;
      pointer-events: none;
      ${EventYear} {
        visibility: hidden;
      }
    }
    & > div {
      display: flex;
      width: 100%;
      outline: 0;
      &:focus {
        outline: 0;
      }
    }
  }
`

const Event = styled("div")`
  position: relative !important;
  padding-left: 40px;
  outline: 0;
  ${mediaQueries.medium(
    css`
      padding-left: 60px;
    `
  )};
`

const EventContent = styled("div")`
`

const EventHeading = styled("h3")`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: ${theme.headings.h2};
  color: ${theme.colors.blue};
  line-height: 1.4;
  font-weight: 700;
`

const EventText = styled("div")`
  color: ${theme.colors.gray};
`

const EventBody = styled("div")`

`

const EventFooter = styled("div")`
  margin-top: 25px;
`

const EventButton = styled(Button)`
  margin-top: 20px;
`

const EventImage = styled(Img)`
  display: block !important;
  width: 100% !important;
`

const EventDate = styled("div")`
  color: ${theme.colors.blue};
  margin-bottom: 20px;
  span {
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
  }
`

const EventInfo = styled("div")`
  margin-top: 20px;
  span {
    display: inline-block;
    width: 20px;
    text-align: center;
    margin-right: 18px;
  }
`

const Download = styled("a")`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 15px;
  color: ${theme.colors.blue};
  text-decoration: none;
  &:hover {
    span {
      text-decoration: underline;
    }
  }
`

const Icon = styled("img")`
  margin-right: 18px;
`

const NextArrow = styled("div")`
  z-index: 2;
  right: 28px;
  top: 25px;
  font-weight: 300;
  font-size: 50px;
  color: ${theme.colors.blue};
  transition: all 0.2s ease-in-out;
  opacity: 1;
  ${mediaQueries.small(
    css`
      right: 40px;
    `
  )};
  ${mediaQueries.medium(
    css`
      right: 70px;
    `
  )};
  &:hover {
    color: ${theme.colors.blue};
  }
  &::before {
    content: none;
  }
  &.slick-disabled {
    visibility: hidden;
    transition: all 0.2s ease-in-out;
    pointer-events: none;
  }
`

const PrevArrow = styled("div")`
  z-index: 2;
  left: 5px;
  top: 25px;
  font-weight: 300;
  font-size: 50px;
  color: ${theme.colors.blue};
  transition: all 0.2s ease-in-out;
  opacity: 1;
  ${mediaQueries.medium(
    css`
      left: 10px;
    `
  )};
  &:hover {
    color: ${theme.colors.blue};
  }
  &::before {
    content: none;
  }
  &.slick-disabled {
    visibility: hidden;
    transition: all 0.2s ease-in-out;
    pointer-events: none;
  }
`

const EventHeader = styled("div")`
  margin-bottom: 35px;
  position: relative;
`

const GalleryButton = styled("div")`
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 0;
  background-color: rgba(38, 29, 93, 0.7);
  padding: 10px 12px;
  pointer-events: none;
  display: flex;
  align-items: center;

  span {
    margin-left: 6px;
    color: ${theme.colors.white};
  }
`

function SliderNextArrow(props) {
  const { className, onClick } = props;
  return (
    <NextArrow
      className={className}
      onClick={onClick}
    >→</NextArrow>
  );
}

function SliderPrevArrow(props) {
  const { className, onClick } = props;
  return (
    <PrevArrow
      className={className}
      onClick={onClick}
    >←</PrevArrow>
  );
}

class PastEvents extends React.Component {
  render() {
    const { data } = this.props
    const { edges: events } = data.allMarkdownRemark

    const settings = {
      dots: false,
      infinite: false,
      speed: 400,
      slidesToShow: 2,
      slidesToScroll: 1,
      centerPadding: "40px",
      draggable: false,
      nextArrow: <SliderNextArrow />,
      prevArrow: <SliderPrevArrow />,
      responsive: [
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: "40px",
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            centerPadding: "40px",
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: "20px",
          },
        },
      ],
    }

      return (
        <EventContainer id="probehle-akce">
          <Row>
            <ColEventHeadingWrapper>
              <ColEventHeading>
                <EventsHeading>Proběhlé akce</EventsHeading>
              </ColEventHeading>
            </ColEventHeadingWrapper>
            <ColEvents>
              <Slider {...settings}>
                {events &&
                  events.map(({ node: event }) => (
                    <Event key={event.id}>
                      <EventContent>
                        <EventYear>{event.frontmatter.year}</EventYear>
                        <EventHeader>
                          <EventImage
                            fixed={
                              event.frontmatter.coverImage.childImageSharp.fixed
                            }
                            alt={event.frontmatter.title}
                          />
                          {event.frontmatter.images && (
                            <div>
                              <Modal>
                                {event.frontmatter.images.map(image => (
                                  <div key={image.image.id}>
                                    <EventImage
                                      fluid={image.image.childImageSharp.fluid}
                                      alt={image.alt}
                                    />
                                  </div>
                                ))}
                              </Modal>
                              <GalleryButton>
                                <img src={CameraIcon} alt="Camera icon" />
                                <span>+{event.frontmatter.images.length}</span>
                              </GalleryButton>
                            </div>
                          )}
                        </EventHeader>
                        <EventHeading>{event.frontmatter.title}</EventHeading>
                        <EventBody>
                          <EventDate>
                            {event.frontmatter.date} <span>|</span> Vstupné:{" "}
                            {event.frontmatter.price}
                          </EventDate>
                          <EventText
                            dangerouslySetInnerHTML={{ __html: event.html }}
                          />
                          <EventFooter>
                            {event.frontmatter.program && (
                              <Download
                                download
                                href={event.frontmatter.program.publicURL}
                              >
                                <Icon src={PDFIcon} alt="Program icon" />
                                <span>
                                  <strong>Program konference </strong> (
                                  {event.frontmatter.program.prettySize})
                                </span>
                              </Download>
                            )}

                            {event.frontmatter.invitation && (
                              <Download
                                download
                                href={event.frontmatter.invitation.publicURL}
                              >
                                <Icon src={PDFIcon} alt="Invitation icon" />
                                <span>
                                  <strong>Oficiální pozvánka</strong> (
                                  {event.frontmatter.invitation.prettySize})
                                </span>
                              </Download>
                            )}
                            {event.frontmatter.registrationStatus.trim() ===
                              "open" && (
                              <EventButton to="/">Přihlásit se</EventButton>
                            )}
                            {event.frontmatter.registrationStatus.trim() ===
                              "close" && (
                              <EventInfo>
                                <span>❗</span>Registrace jsou uzavřené
                              </EventInfo>
                            )}
                            {event.frontmatter.registrationStatus.trim() ===
                              "canceled" && (
                              <EventInfo>
                                <span>❗</span>Událost byla zrušena
                              </EventInfo>
                            )}
                            {event.frontmatter.registrationStatus.trim() ===
                              "preparation" && (
                              <EventInfo>
                                <span>❗</span>Registrace otevřeme{" "}
                                {event.frontmatter.openingRegistrationDate}
                              </EventInfo>
                            )}
                          </EventFooter>
                        </EventBody>
                      </EventContent>
                    </Event>
                  ))}
              </Slider>
            </ColEvents>
          </Row>
        </EventContainer>
      )
  }
}

PastEvents.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query PastEventsQuery {
        allMarkdownRemark(
          limit: 1000
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: {
            frontmatter: {
              registrationStatus: { in: ["past", "canceled"] }
              templateKey: { eq: "event" }
            }
          }
        ) {
          edges {
            node {
              id
              html
              frontmatter {
                title
                templateKey
                date(formatString: "DD. MM. YYYY")
                year: date(formatString: "YYYY")
                openingRegistrationDate(formatString: "DD. MM. YYYY")
                registrationStatus
                price
                coverImage {
                  childImageSharp {
                    fixed(width: 340, height: 280, quality: 90) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
                images {
                  image {
                    id
                    childImageSharp {
                      fluid(quality: 90) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  alt
                }
                invitation {
                  prettySize
                  publicURL
                }
                program {
                  prettySize
                  publicURL
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <PastEvents data={data} count={count} />}
  />
)
