import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import { theme, mediaQueries } from "./theme"
import { Container } from "./Container"
import Button from "./Button"
import { css } from "@emotion/core"
import Marked from "react-markdown"

const CTAContainer = styled(Container)`
  padding-top: 80px;
  padding-bottom: 80px;
  text-align: center;
  background-color: ${theme.colors.white};
  ${mediaQueries.small(
    css`
      padding-top: 160px;
      padding-bottom: 160px;
    `
  )};
`

const Text = styled("div")`
  max-width: 732px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
  font-size: ${theme.fontSizes.large};
  line-height: 1.6666666667;
  color: ${theme.colors.blue};
`

export default () => {
  const data = useStaticQuery(graphql`
    query ctaContactQuery {
      markdownRemark(frontmatter: { templateKey: { eq: "settings" } }) {
        frontmatter {
          partnershipClaim
          contactEmail
        }
      }
    }
  `)

  return (
    <>
      <div id="partnerstvi">
        <CTAContainer line>
          <Text>
            <Marked source={data.markdownRemark.frontmatter.partnershipClaim} />
          </Text>
          <Button to={'mailto:'+data.markdownRemark.frontmatter.contactEmail}>
            Oslovte nás
          </Button>
        </CTAContainer>
      </div>
    </>
  )
}
