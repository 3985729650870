import React from "react"
import styled from "@emotion/styled"
import { theme, mediaQueries } from "./theme"
import { Container } from "./Container"
import Button from "./Button"
import LogoSimple from "./logo_simple.svg"
import { css } from "@emotion/core"
import { useStaticQuery, graphql } from "gatsby"
import Marked from "react-markdown"

const JumbotronWrapper = styled("div")`
  padding-top: 40px;
  padding-bottom: 60px;
  background-color: ${theme.colors.blue};
  ${mediaQueries.small(
    css`
      padding-bottom: 116px;
    `
  )};
`

const JumbotronContainer = styled(Container)`
  max-width: 1140px;
`

const Row = styled("div")`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

const Col = styled("div")`
  max-width: 100%;
  flex: 0 0 100%;
  ${mediaQueries.medium(
    css`
      max-width: 50%;
      flex: 0 0 50%;
    `
  )};
`

const ColImage = styled(Col)`
  display: none;
  ${mediaQueries.medium(
    css`
      display: block;
    `
  )};
`

const Heading = styled("h1")`
  margin-bottom: 35px;
  font-size: ${theme.headingsMobile.h1};
  color: ${theme.colors.white};
  line-height: 1;
  text-transform: uppercase;
  font-weight: 300;
  ${mediaQueries.medium(
    css`
      font-size: ${theme.headings.h1};
    `
  )};

  strong {
    display: block;
    font-weight: 700;
  }
`

const Text = styled("div")`
  max-width: 426px;
  margin-bottom: 35px;
  color: ${theme.colors.white};
`

const JumbotronLogo = styled("img")`
  display: block;
  margin-left: auto;
`

export default () => {
  const data = useStaticQuery(graphql`
    query jumbotronQuery {
      markdownRemark(frontmatter: { templateKey: { eq: "settings" } }) {
        frontmatter {
          jumbotronTitle
          jumbotronClaim
        }
      }
    }
  `)

  return (
    <>
      <JumbotronWrapper>
        <JumbotronContainer>
          <Row>
            <Col>
              <Heading>
                <Marked
                  source={data.markdownRemark.frontmatter.jumbotronTitle}
                />
              </Heading>
              <Text>
                <Marked
                  source={data.markdownRemark.frontmatter.jumbotronClaim}
                />
              </Text>
              <Button to="#kalendar-akci">Kalendář našich akcí</Button>
            </Col>
            <ColImage>
              <JumbotronLogo src={LogoSimple} alt="Logo Educon Prague" />
            </ColImage>
          </Row>
        </JumbotronContainer>
      </JumbotronWrapper>
    </>
  )
}
